import EventEmitter from 'eventemitter3';

/**
 * Handles the storage (Memory & localStorage).
 *
 * @version 2.0.0
 */
class StorageManagerClass extends EventEmitter {


  /**
   * Constructor.
   *
   * @class
   * @version 2.0.0
   */
  constructor() {

    super();

    // log
    console.log('💽 StorageManager', 'Function: Constructor');

    // init memory
    this.memory = {};
    this.prefix = 'StorageManager_Billing';

  }


  /**
   * Function to retrieve the value of the key that is passed through.
   *
   * @param {string} key - This is the value realm will search for in the localStorage
   */
  get(key) {

    // check if we have it in memory
    if (typeof this.memory[key] !== 'undefined') {

      // log
      // console.debug('💽 StorageManager', 'Function: Get', key);

      // return from memory
      // I have a good memory.
      return this.memory[key];

    } else {


      // get
      const object = localStorage.getItem(`${this.prefix}_${key}`);

      // check if it exists
      let returnValue;
      if (object === null) {

        returnValue = null;

      } else {

        // return a copy
        // dereference
        returnValue = `${object}`;

        // parse
        try {

          // go time
          returnValue = JSON.parse(returnValue).v;

        } catch (e) {}

      }

      // log
      // console.debug('💽 StorageManager', 'Function: Get', key);

      // return
      // save in memory
      return this.memory[key] = returnValue;

    }

  }

  /**
   * Save an object to the realm database.
   *
   * @param {string} key - The name where the value should be stored on.
   * @param {Object} value - Value that the key should contain.
   */
  set(key, value) {

    // log
    // console.log('💽 StorageManager', 'Function: Set', key);

    // stringify value
    const stringValue = JSON.stringify({
      v: value,
    });

    // save
    localStorage.setItem(`${this.prefix}_${key}`, stringValue);

    // save in memory
    this.memory[key] = value;

    // emit
    this.emit('update', key, value);

    // return
    return value;

  }

}

// singleton
export default (new StorageManagerClass());
