import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

// eslint-disable-next-line no-unused-vars
import Moment from 'react-moment';
import 'moment-timezone';

// auth class
import Auth from './classes/auth.class.js';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Class App
class App extends Component {

  constructor() {

    // man
    super();

    // init
    this.state = {
      authenticated: Auth.isAuthenticated(),
    }

    // listen
    Auth.on('update', this.setAuth.bind(this));

  }

  setAuth() {

    // init
    this.setState({
      authenticated: Auth.isAuthenticated(),
    });

  }

  render() {

    // check
    if(this.state.authenticated !== true){

      return <span>Redirecting...</span>;

    }

    return (
      <BrowserRouter>
          <React.Suspense fallback={<div className="pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>}>
          <Switch>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
