// modules
import queryString from 'query-string';
import EventEmitter from 'eventemitter3';
import axios from 'axios';

// classes
import Storage from './storage.class.js';

class Auth extends EventEmitter  {

  constructor() {

    super();

    // init
    this.user = {};
    this.baseUrl = process.env.REACT_APP_API_BASE_URL ||  'http://localhost:4000';


    // axios instance
    this.axios = axios.create({
      baseURL: this.baseUrl,
      withCredentials: true,
    });

    // query params
    const queryParams = queryString.parse(window.location.search);
    if(typeof queryParams.code !== 'undefined') {

      // obtain the redirect query params
      this.setAuthToken(queryParams);

    } else {

      // check the login session
      this.checkAndVerifyToken();

    }

  }

  isAuthenticated() {

    return Storage.get('AuthToken') !== null;

  }

  getUser() {

    return this.user;

  }

  async getAuthToken() {

    // get the token
    let authToken = Storage.get('AuthToken') || null;

    // if it's null, we dont need to validate expiration
    if(authToken === null) {

      return null;

    }

    // check if it was expired
    const tokenExpiryCheck = await this.checkTokenNotExpired();
    if(tokenExpiryCheck === false) {

      // stop
      throw new Error('Token expired');

    } else
    if(typeof tokenExpiryCheck === 'string') {

      // overwrite
      authToken = tokenExpiryCheck;

    }

    // return the value
    // if the token was expired and refreshed in the mean time, it was written to storage
    return authToken;

  }

  logout() {

    Storage.set('AuthToken', null);
    Storage.set('AuthTokenExpiry', null);
    Storage.set('RefreshToken', null);

    window.location.assign(`${this.baseUrl}/auth/logout`)

  }

  async checkTokenNotExpired(){

    // expiry
    const expiry = Storage.get('AuthTokenExpiry');
    const inOneMinute = new Date().getTime() + (1000 * 60);

    // check if the token is going to expire in the next minute
    if(expiry > inOneMinute) {

      // done
      return true;

    }

    // refresh
    let refreshResult = null;
    try {

      refreshResult = await this.axios.post('auth/refresh', {
        refresh_token: Storage.get('RefreshToken'),
      });

    } catch (e) {

      // redirecting to login
      console.log('Error in refreshing token, logging out', e);
      this.redirectToLogin();

      return false;

    }

    // prepare saving
    const tokens = refreshResult.data.response;
    const tokenExpiry = new Date().getTime() + (tokens.expires_in * 1000);

    // save
    Storage.set('AuthToken', tokens.access_token);
    Storage.set('AuthTokenExpiry', tokenExpiry);
    Storage.set('RefreshToken', tokens.refresh_token);

    // log
    console.log('Auth - Set new token which is to expire at', new Date(tokenExpiry), '- refresh token has been saved.');

    // emit
    this.emit('update');

    // return
    return tokens.access_token;

  }

  async setAuthToken(queryParams) {

    // pass through to api
    let result;
    try {

      result = await this.axios.get('auth/callback', {
        params: queryParams,
      });

      if(result.data.accessToken.statusCode !== 200) {

        this.checkAndVerifyToken();
        return;

      }

    } catch (e) {

      this.checkAndVerifyToken();
      return;

    }

    // prepare saving
    const tokens = result.data.accessToken.response;
    const tokenExpiry = new Date().getTime() + (tokens.expires_in * 1000);

    // save
    Storage.set('AuthToken', tokens.access_token);
    Storage.set('AuthTokenExpiry', tokenExpiry);
    Storage.set('RefreshToken', tokens.refresh_token);

    // log
    console.log('Auth - Set new token which is to expire at', new Date(tokenExpiry), '- refresh token has been saved.');

    // get and verify user login
    this.checkAndVerifyToken();

    // emit
    this.emit('update');

  }

  async checkAndVerifyToken() {

    // init
    const authToken = await this.getAuthToken();

    // check if we have no token in the first place
    if(authToken === null) {

      // go to the login page
      this.redirectToLogin();
      return;

    }

    // validate the current token
    let user = null;
    try {

      user = await this.axios.get('auth/user', {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

    } catch (e) {

      console.log('Error in authentication', e);

    }

    // delete token if invalid
    if(user === null || user.status !== 200) {

      // remove tokens from storage
      Storage.set('AuthToken', null);
      Storage.set('RefreshToken', null);

      // to the login page
      this.redirectToLogin();
      return;

    }

    // save user
    this.user = user.data;

    this.emit('userUpdate', this.user);

  }

  async redirectToLogin() {

    // get login url
    let result;
    try {

      // get url and redirect
      result = await this.axios.get('auth/login', {
        params: {
          redirect: false,
        },
      });

      const queryParams = queryString.parse(window.location.search);
      if(typeof queryParams.code !== 'undefined') {

        window.alert('Login loop detected');
        return;

      }

      // go
      window.location.assign(result.data.loginUrl)

    } catch (e) {

      console.error(e);

      // errors
      window.alert(`Something went wrong, please try again...\n\n${JSON.stringify(e)}`);

    }

  }

}

// singleton
export default (new Auth());
